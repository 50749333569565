.productGallery {
  display: flex;
  gap: 24px;
  @media (max-width: 767px) {
    gap: .75rem;
    margin-top: 1.25rem;
  }
  @media (max-width: 576px) {
    padding: 20px;
  }
}
.modalContent{
  text-align: center;
  
  & .modalTitle{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  & .modalText{
    font-size: .875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  & .buttonGroups{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 40px;
  }
  & .cancelBtn{
    width: 8rem;
    min-height: 40px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    font-weight: 400;
  }
  & .removeBtn{
    width: 8rem;
    min-height: 40px;
    border-radius: 50px;
    font-weight: 400;
  }
}
.modalRemove{
  & > div{
    & > div{
      flex-basis: 448px !important;
      & > button{
        display: none !important; 
      }
    }
  }
}

.deleteIcon{
  height: 72px;
  width: 72px;
  fill: #000;
}
.leftSideNav {
  max-width: 131px;
  width: 131px;
  @media (max-width: 767px) {
    width: 98px;
  }
  @media (max-width: 576px) {
    width: 81px;
    max-width: 81px;
  }
  & img {
    width: 100%;
  }
  & :global(.slick-slider) {
    height: 777px;
    position: relative;
    @media (max-width: 1280px) {
      height: 457px;
    }
    @media (max-width: 767px) {
      height: 380px;
    }
    @media (max-width: 576px) {
      height: 336px;
    }
  }
  & :global(.slick-list) {
    height: 777px !important;
    @media (max-width: 1280px) {
      height: 457px !important;
    }
    @media (max-width: 767px) {
      height: 380px !important;
    }
    @media (max-width: 576px) {
      height: 336px !important;
    }
  }
  & :global(.slick-slide) {
    padding: 15px 0;
  }
  & :global(.slick-arrow) {
    background: rgba(107, 114, 128, 0.5);
    left: 0;
    right: 0;
    width: 100%;
  }
  & :global(.slick-next) {
    background: rgba(107, 114, 128, 0.5);
    left: 0;
    right: 0;
    width: 100%;
    bottom: -8px;
    top: unset;
    &:before {
      content: '' !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.52955 10.164C2.61867 9.98652 2.77452 9.85167 2.96291 9.78896C3.15131 9.72626 3.35688 9.74082 3.53455 9.82945L12.2001 14.16L20.8641 9.82795C20.9522 9.78292 21.0484 9.75579 21.1471 9.74811C21.2457 9.74043 21.345 9.75236 21.439 9.78321C21.5331 9.81407 21.6201 9.86323 21.695 9.92787C21.77 9.99251 21.8314 10.0713 21.8758 10.1598C21.9201 10.2483 21.9465 10.3447 21.9534 10.4435C21.9603 10.5422 21.9476 10.6413 21.916 10.7351C21.8845 10.8289 21.8346 10.9156 21.7694 10.99C21.7042 11.0645 21.6249 11.1253 21.5361 11.169L12.5361 15.669C12.4318 15.7212 12.3167 15.7484 12.2001 15.7484C12.0834 15.7484 11.9683 15.7212 11.8641 15.669L2.86405 11.169C2.68662 11.0798 2.55177 10.924 2.48907 10.7356C2.42636 10.5472 2.44092 10.3416 2.52955 10.164Z' fill='white'/%3E%3C/svg%3E");
      height: 22px;
      width: 22px;
      display: block;
      margin: 0 auto;
    }
  }
  & :global(.slick-prev) {
    background: rgba(107, 114, 128, 0.5);
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    bottom: unset;
    top: 0;
    @media (max-width: 767px) {
        top: 10px;
      }
    &:before {
      content: '' !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.864 8.32947C11.9683 8.27721 12.0834 8.25 12.2 8.25C12.3167 8.25 12.4317 8.27721 12.536 8.32947L21.536 12.8295C21.6248 12.8731 21.7042 12.9339 21.7694 13.0084C21.8346 13.0829 21.8844 13.1695 21.916 13.2633C21.9476 13.3571 21.9603 13.4562 21.9534 13.555C21.9465 13.6537 21.9201 13.7501 21.8757 13.8386C21.8314 13.9271 21.77 14.0059 21.695 14.0706C21.6201 14.1352 21.533 14.1844 21.439 14.2152C21.3449 14.2461 21.2457 14.258 21.147 14.2503C21.0483 14.2426 20.9522 14.2155 20.864 14.1705L12.2 9.83997L3.53602 14.1705C3.44787 14.2155 3.35169 14.2426 3.25301 14.2503C3.15432 14.258 3.0551 14.2461 2.96105 14.2152C2.867 14.1844 2.77999 14.1352 2.70503 14.0706C2.63007 14.0059 2.56865 13.9271 2.5243 13.8386C2.47996 13.7501 2.45357 13.6537 2.44666 13.555C2.43974 13.4562 2.45244 13.3571 2.48402 13.2633C2.5156 13.1695 2.56544 13.0829 2.63066 13.0084C2.69587 12.9339 2.77519 12.8731 2.86402 12.8295L11.864 8.32947Z' fill='white'/%3E%3C/svg%3E");
      height: 22px;
      width: 22px;
      display: block;
      margin: 0 auto;
    }
  }
}

.mainImg {
  flex: 1;
  position: relative;
  max-height: 782px;
  @media (max-width:767px) {
    
    max-height: 335px;
  }
& .galleryImage{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
  & figure {
    height: 100%;
    max-height: 777px;
    overflow: hidden;
    width: 100%;
    @media (max-width: 576px) {
      max-height: 336px;
    }
    & > div {
      height: 100%;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    & > img {
      /* width: 1500px !important;  */

      /* object-fit: cover; */
    }
  }
  & .likeIcon {
    position: absolute;
    top: 24px;
    left: 20px;
    z-index: 2;
    background: var(--Sand---Primary, #efe4d3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
