:global(.carousel-container-with-scrollbar) {
  padding-left: 100px;
  padding-bottom: 20px;
  overflow: visible !important;
}
:global(.custom-slider) {
  bottom: 0;
  width: 100%;
  padding: 0 0px;
  @media (max-width: 1023px) {
    display: none;
  }

  & input[type='range'] {
    -webkit-appearance: none;
  }

  & input[type='range']::-webkit-slider-runnable-track {
    width: 300px;
    height: 10px;
    background: #edf5fa;
    border: none;
    border-radius: 3px;
  }
  & input {
    border: 0;
    background: transparent;
  }

  & input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 10px;
    width: 15%;
    border-radius: 0%;
    background:#b06b5d;
    margin-top: 0px;
  }

  & input[type='range']:focus {
    outline: none;
    box-shadow: none;
  }

  & input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }
}
:global(.custom-slider__input) {
  width: 100%;
}
:global(.slider-image-item) {
  padding: 20px 0 20px 20px;
  @media (max-width: 1023px) {
    padding: 20px 0 0px 20px;
  }
}

:global(.react-multi-carousel-track) {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  & > li {
    flex: none !important;
    & > div{
      height: 100%;
      & >a{
        height: 100%;
      }
    }
  }
}
.carouselContainer {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  /* overflow: hidden; */
  position: relative;
  @media (max-width: 1600px) {
    max-width: calc(100vw - 0px);
  }
}

:global(.carousel-container-with-scrollbar) {
  max-width: 1480px;
  padding: 0 0px 0 0px;
  overflow: hidden !important;
  @media (max-width: 1600px) {
    max-width: calc(100vw - 80px);
  }
  @media (max-width: 1023px) {
    max-width: 100vw;
  }
}

:global(.react-multi-carousel-item) {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.sliderRightButton {
  position: absolute;
  right: -30px;
  top: 40%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.7);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg{
    height: 15px;
    width: 15px;
  }
  @media (max-width: 1600px) {
    right: 30px;
    top: 32%;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}

.sliderLeftButton {
  position: absolute;
  left: -30px;
  top: 40%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.7);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  & svg{
    height: 15px;
    width: 15px;
  }
  @media (max-width: 1600px) {
    right: 30px;
    top: 32%;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}

:global(.react-multiple-carousel__arrow--left) {
  display: none;
}
